@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'AeonikPro-Regular';
    src: url('/fonts/AeonikPro-Regular.woff') format('woff');
    src: url('/fonts/AeonikPro-Regular.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Italic';
    src: url('/fonts/AeonikPro-RegularItalic.woff') format('woff');
    src: url('/fonts/AeonikPro-RegularItalic.woff2') format('woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Air';
    src: url('/fonts/AeonikPro-Air.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-AirItalic';
    src: url('/fonts/AeonikPro-AirItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Black';
    src: url('/fonts/AeonikPro-Black.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-BoldItalic';
    src: url('/fonts/AeonikPro-BoldItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Thin';
    src: url('/fonts/AeonikPro-Thin.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-LightItalic';
    src: url('/fonts/AeonikPro-LightItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Medium';
    src: url('/fonts/AeonikPro-Medium.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-AirItalic';
    src: url('/fonts/AeonikPro-AirItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Air';
    src: url('/fonts/AeonikPro-Air.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-AirItalic';
    src: url('/fonts/AeonikPro-AirItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-Air';
    src: url('/fonts/AeonikPro-Air.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AeonikPro-AirItalic';
    src: url('/fonts/AeonikPro-AirItalic.woff') format('woff');
    font-display: swap;
  }

  html {
    @apply font-regular text-base text-black leading-5.5 tracking-tight;
  }

  b {
    @apply font-sans-bold;
  }

  i {
    @apply font-sans-italic;
  }

  h1 {
    @apply font-regular text-[40px] lg:text-6xl leading-[45px] lg:leading-[72px];
  }

  h2 {
    @apply font-regular text-2xl lg:text-5xl leading-6.5 lg:leading-[62px];
  }

  h3 {
    @apply font-regular text-lg lg:text-4xl leading-5.5 lg:leading-[43px];
  }

  h4 {
    @apply font-regular text-lg lg:text-[32px] leading-5.5 lg:leading-[38px];
  }

  h5,
  .h5 {
    @apply font-regular text-base lg:text-[28px] leading-5 lg:leading-[34px];
  }

  .strikeAnimation:before,
  .strikeAnimation:after {
    @apply absolute w-0 h-px top-1/2 -mt-[0.5px] bg-white;
    content: '';
  }

  .strikeAnimation:before {
    @apply left-[-2.5px];
  }

  .strikeAnimation:after {
    @apply right-[2.5px] bg-white;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  .strikeAnimationActive {
    @apply font-sans-italic;
  }

  .strikeAnimationActive:before {
    @apply bg-white w-full;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  .strikeAnimationActive:after {
    @apply bg-transparent w-full;
    transition: 0s;
  }
}
